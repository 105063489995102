import config from "../config";
import {convert} from 'html-to-text'
function escapeHtml (unsafe) {
  return unsafe ? unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&quot;')
    .replace(/'/g, '&#039;') : ''
}

function unescapeHtml(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

function highlightKeyword (str, keyword) {
  let lastEnd = 0
  let index = -1
  const result = []
  while ((index = str.indexOf(keyword, lastEnd)) !== -1) {
    if (index > lastEnd) {
      result.push(escapeHtml(str.substring(lastEnd, index)))
    }
    result.push('<em>')
    result.push(escapeHtml(keyword))
    result.push('</em>')
    lastEnd = index + keyword.length
  }
  if (str.length > lastEnd) {
    result.push(escapeHtml(str.substring(lastEnd)))
  }
  return result.join('')
}

function scrollElementIntoView(selectors) {
  const element = document.querySelector(selectors)
  if (element) {
    element.scrollIntoView()
  }
  return element
}

function tryReplaceAttachmentUrl(ele, attrName){
  const value = ele.getAttribute(attrName)
  if(!value){
    return
  }
  if(!value.startsWith("http")){
    return
  }
  let url
  try {
    url = new URL(value)
  } catch (e) {
    return
  }
  if(!url.pathname.endsWith("/files")){
    return
  }
  const path=url.searchParams.get("path")
  if(!path){
    return
  }
  ele.setAttribute(attrName,config.pmedServiceUrl + 'files?path=' + encodeURIComponent(path))
}
function tryReplaceFontSize(ele,standardFontSize){
  if(!ele.style.fontSize){
    return
  }
  const matchResult = /^(\d+)(\D+)$/.exec(ele.style.fontSize)
  if(matchResult[2]==='px'){
    ele.style.fontSize=(parseInt(matchResult[1])/standardFontSize)+'em'
  }
}
function normalizeHtml(html,standardFontSize){
  if(!html){
    return null
  }
  // https://testhost:8443/files?path=d%2F60f874fc-6800-47ac-8538-f96236ee08a4.png
  const parser = new DOMParser()
  // 将后台的附件地址转换成前台的附件地址
  const doc = parser.parseFromString(html, "text/html")
  doc.querySelectorAll("img").forEach(ele=>{
    tryReplaceAttachmentUrl(ele,"src")
  })
  // 将font-size转换成相对单位em，方便进行文本缩放
  doc.querySelectorAll("[style]").forEach(ele=>{
    tryReplaceFontSize(ele,standardFontSize)
  })
  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc)
}

function extractPlainText(html){
  if(!html){
    return null
  }
  return convert(html)
}

function richTextToHtml(str){
  if(!str){
    return ''
  }
  const regex=/(.+)((\r?\n)+|$)/g
  let result
  const html=[]
  while((result=regex.exec(str))){
    let content=result[1].trim()
    if(content){
      const regex2=/<IMAGE SRC="([^"]+)">/g
      let result2
      const html2=[]
      let prevIdx=0
      while((result2=regex2.exec(content))){
        html2.push(escapeHtml(content.substring(prevIdx,result2.input)))
        html2.push('<img src="'+result2[1]+'">')
        prevIdx=regex2.lastIndex
      }
      if(prevIdx<content.length){
        html2.push(escapeHtml(content.substring(prevIdx)))
      }
      html.push('<p class="text">',html2.join(''),'</p>')
    }
  }
  return html.join('')
}
export default {
  escapeHtml,
  unescapeHtml,
  highlightKeyword,
  scrollElementIntoView,
  normalizeHtml,
  extractPlainText,
  richTextToHtml
}
