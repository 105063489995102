<template>
  <div class="block">
    <div class="block-header">
      <div class="block-title">
        <span v-if="!$slots.title">{{title}}</span>
        <slot v-else name="title"></slot>
      </div>
      <div class="block-actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="block-body" style="position: relative;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Block",
  props:{
    title:String
  }
}
</script>

<style scoped>

</style>
