import config from '../config'

function updateAttribute (el, binding) {
  if (binding.value) {
    el.setAttribute(binding.arg, config.gatewayUrl + 'file/download?name=' + encodeURIComponent(binding.value))
  } else {
    el.removeAttribute(binding.arg)
  }
}

export default {
  bind (el, binding) {
    updateAttribute(el, binding)
  },
  update (el, binding) {
    if (binding.value === binding.oldValue) {
      return
    }
    updateAttribute(el, binding)
  }
}
